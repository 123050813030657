<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
    >
      <v-tabs
        centered
        color="grey darken-1"
        icons-and-text
      >
        <v-tab
          v-for="link in links"
          :key="link.title"
          :to="{ name: link.pathName }"
        >
          {{ link.title }}
          <v-icon color="green">{{ link.icon }}</v-icon>
        </v-tab>
      </v-tabs>
      <v-btn
        href="/"
        class="text-capitalize"
        outlined
        color="green"
      >
        Dat Hong
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-sheet
              min-height="80vh"
              rounded="lg"
            >
              <v-fab-transition>
                <router-view></router-view>
              </v-fab-transition>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        title: 'Profile',
        pathName: 'Home',
        icon: 'mdi-account-details'
      },
      {
        title: 'Resume',
        pathName: 'Resume',
        icon: 'mdi-school'
      },
      {
        title: 'Portfolio',
        pathName: 'Portfolio',
        icon: 'mdi-briefcase'
      }
    ]
  })
}
</script>
