import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
const Resume = () => import(/* webpackChunkName: "home" */ '@/views/Resume.vue')
const Portfolio = () => import(/* webpackChunkName: "home" */ '@/views/Portfolio.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
